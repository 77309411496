<template>
  <div>
    <ResendMailModal
      v-if="openResendMailModal"
      @close="openResendMailModal = false"
      :firstName="firstNameModal"
      :lastName="lastNameModal"
    >
    </ResendMailModal>
    <div class="buttons-container">
      <router-link :to="{ name: 'MonitoringPatients' }">
        <Button title="Tableaux" type="primary" :active="true" />
      </router-link>
      <router-link :to="{ name: 'MonitoringPatientsCharts' }">
        <Button title="Graphs" type="primary" />
      </router-link>
    </div>
    <Modal
      v-if="openCommentDetailModal"
      @close="openCommentDetailModal = false"
      className="size-modal container-comment-modal"
    >
      <div class="container-modal comment-modal">
        <span class="font-title-modal">Commentaire</span>
        <Input
          v-model="patient.comment"
          :labelClass="'flex'"
          :containerClass="'w-100 comment-input'"
          isDisabled
          type="textarea"
        />
      </div>
      <div class="w-10 mr-auto">
        <Button
          title="Fermer"
          type="secondary"
          size="small"
          @click="openCommentDetailModal = false"
        />
      </div>
    </Modal>
    <Modal
      v-if="isShowEditModal"
      @close="isShowEditModal = false"
      :className="'size-modal-patient'"
    >
      <div class="container-modal">
        <span class="font-title-modal">1. Informations du patient</span>
        <div class="flex mr-top-30">
          <Input
            :labelClass="'flex'"
            :containerClass="'w-100'"
            title="Nom *"
            v-model="patientToEdit.lastName"
          />
          <Input
            v-model="patientToEdit.firstName"
            title="Prénom *"
            :labelClass="'flex'"
            :containerClass="'w-100'"
          />
        </div>
        <div class="flex">
          <Input
            v-model="patientToEdit.phone"
            :labelClass="'flex'"
            :containerClass="'w-30'"
            title="Téléphone"
          />
          <div class="w-100">
            <Input
              v-model="patientToEdit.email"
              title="Adresse mail *"
              :labelClass="'flex'"
              @input="errorMsgEmail = false"
              :containerClass="'w-50'"
              :inputClassName="errorMsgEmail ? 'border-red' : ''"
            />
            <span class="email-warning-txt" v-if="errorMsgEmail"
              >Veuillez entrer un email valide</span
            >
          </div>
        </div>
        <div class="width-btn">
          <Button
            title="Sauvegarder"
            type="secondary"
            @click="savePatient"
            :disabled="isReadyToSave"
          />
        </div>
      </div>
    </Modal>
    <div class="monitoring-table">
      <table class="w-100" aria-describedby="monitoring-table">
        <thead>
          <tr>
            <template v-for="(group, i) in displayColumns">
              <template v-for="(col, j) in group.fields">
                <th
                  :key="`col-${i}-${j}`"
                  :class="{ sortable: col.sortable, group: j === 0 }"
                  @click="sort(group, col)"
                >
                  {{ col.title }}
                </th>
              </template>
            </template>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(patient, i) in patientsList" :key="i">
            <td>{{ patient.lastName }}</td>
            <td>{{ patient.firstName }}</td>
            <td>{{ patient.email }}</td>
            <td>{{ patient.phone }}</td>
            <td>{{ patient.establishmentName }}</td>
            <td>
              <div class="svg-container">
                <SVGEdit @click="editPatient(patient)" />
              </div>
            </td>
            <td>
              <div>
                <Button
                  :title="'Renvoyer le lien'"
                  :type="'primary'"
                  size="small"
                  @click="resendSignupLink(patient)"
                />
              </div>
            </td>
            <td>
              <div>
                <Button
                  :title="'Fermer le compte'"
                  :type="'delete'"
                  size="small"
                  disabled
                />
                <!-- <Button
                    :title="patient.isEnabled ? 'Fermer le compte' : 'Réactiver le compte'"
                    :type="patient.isEnabled ? 'primary' : 'delete'"
                    size="small"
                    @click="openConfirmModal(patient)"
                  /> -->
              </div>
            </td>
            <td>
              <div>
                <Button
                  title="Supprimer le compte"
                  type="delete-type"
                  :disabled="true"
                  size="small"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button"
import SVGEdit from "@/assets/icons/edit.svg"
import { mapGetters } from "vuex"
import Modal from "../../components/ConfirmationModal/Modal.vue"
import Input from "../../components/Forms/Fields/Input.vue"
import { isEmail } from "../../utils/validation"
import ResendMailModal from "../../components/ConfirmationModal/ResendMailModal.vue"

export default {
  name: "MonitoringPatients",
  components: {
    Button,
    SVGEdit,
    Input,
    Modal,
    ResendMailModal
  },
  data() {
    return {
      firstNameModal: "",
      lastNameModal: "",
      openResendMailModal: false,
      openCommentDetailModal: false,
      isCloseAccountModal: false,
      patientToEdit: {},
      errorMsgEmail: false,
      isShowEditModal: false,
      columns: {
        main: {
          name: "main",
          title: "",
          display: true,
          hidden: true,
          fields: [
            { title: "Nom", sortable: true, name: "lastname" },
            { title: "Prénom", sortable: true, name: "firstname" },
            { title: "Email", sortable: true, name: "email" },
            { title: "Téléphone", sortable: true, name: "phone" },
            { title: "Etablissement de santé", sortable: true, name: "establishment" },
            { title: "Editer la ligne" },
            { title: "Renvoie du mail d'inscription", name: "manage" },
            { title: "Fermeture Compte" },
            { title: "Supprimer Compte" }
          ]
        },
        area: {
          name: "area",
          title: "Zone",
          display: false,
          fields: [
            { title: "Pays", sortable: true, name: "country", editable: true },
            { title: "Agence(s)" },
            { title: "Département / Province" },
            { title: "Nom Étab. / Domicile", sortable: true, name: "establishmentName" }
          ]
        },
        patientDetails: {
          name: "patientDetails",
          title: "Détail patients",
          display: false,
          fields: [
            { title: "Code Postal", sortable: true, name: "zipcode", editable: true },
            { title: "Ville", sortable: true, name: "city", editable: true },
            { title: "N°", editable: true },
            { title: "Voie", editable: true },
            { title: "Précision", editable: true },
            { title: "Email", sortable: true, name: "email", editable: true },
            { title: "ID Patient" }
          ]
        },
        state: {
          name: "state",
          title: "État",
          display: false,
          fields: [
            { title: "Capacité Fonctionnelle" },
            { title: "Pathologie Principale" }
          ]
        },
        usage: {
          name: "usage",
          title: "Usage et perf. (7 derniers jours)",
          display: false,
          fields: [
            { title: "Jours d'usage", sortable: true, name: "usageDays" },
            {
              title: "Tps d'utilisation / Jour d'usage",
              sortable: true,
              name: "usageTime"
            },
            {
              title: "Tx Performance / jours d'usage",
              sortable: true,
              name: "performance"
            },
            { title: "Tx autorééduc effectuée", sortable: true, name: "selfWorkout" },
            { title: "Nbre fois en ligne", sortable: true, name: "online" },
            { title: "Temps  passé en ligne", sortable: true, name: "onlineTime" },
            { title: "Dernier succès" },
            { title: "Rang actuel de ce mois" },
            {
              title: "Total d’activités réalisées cette semaine",
              sortable: true,
              name: "activities"
            }
          ]
        },
        lifebloomTeam: {
          name: "lifebloomTeam",
          title: "Équipe Lifebloom",
          display: false,
          fields: [{ title: "Expert Lifebloom Associé" }, { title: "Rééducateur" }]
        },
        coaching: {
          name: "coaching",
          title: "Programmer coaching",
          display: false,
          fields: [
            { title: "Nb coaching effectués", sortable: true, name: "coachingCount" },
            { title: "Dernier coaching" },
            { title: "Prochain coaching" },
            { title: "Ne souhaite plus de coaching" }
          ]
        },
        equipment: {
          name: "equipment",
          title: "Matériel",
          display: false,
          fields: [
            { title: "Identifiant dispositif/N° Oxilio" },
            { title: "Taille de roues" },
            { title: "Deadline maintenance annuelle" },
            { title: "Changement d'oxilio" }
          ]
        },
        billing: {
          name: "billing",
          title: "Comptabilité / Facturation",
          display: false,
          fields: [
            { title: "Mandat de Prélèvement" },
            { title: "Etat infos bancaires" },
            { title: "Contrat" },
            { title: "Date session de lancement" },
            { title: "Total coach indiv inclus au lancement" },
            { title: "Nb mois d'engagement restant" },
            { title: "Nb Coaching Individuel du dernier mois plein" },
            { title: "Facture du dernier mois plein" }
          ]
        },
        billingDetails: {
          name: "billingDetails",
          title: "Adresse et historique de facturation",
          display: false,
          fields: [
            { title: "Pays" },
            { title: "CP" },
            { title: "Ville" },
            { title: "N°" },
            { title: "Voie" },
            { title: "Précision" },
            { title: "Nom" },
            { title: "Prénom" },
            { title: "Email" },
            { title: "Téléphone" },
            { title: "Mois n-1 nb de coaching" },
            { title: "Facture Mois n-1 / Année AAAA" },
            { title: "Historique des Factures" }
          ]
        },
        closeAccount: {
          name: "closeAccount",
          title: "Fin de compte",
          display: false,
          fields: [
            { title: "Amorçer la procédure de fin d'abonnement" },
            { title: "Fermeture de compte" },
            { title: "Suppression de compte" }
          ]
        }
      },
      sortBy: {
        group: "main",
        key: "lastname",
        order: 1
      }
    }
  },
  async created() {
    await this.$store.dispatch("admin/getAdminPatientApplications")
  },
  methods: {
    momentDate(date) {
      const moment = require("moment")
      return moment(new Date(date)).format("YYYY-MM-DD")
    },
    async resendSignupLink(patient) {
      const resp = await this.$store.dispatch("admin/resendPatientSignUpLink", patient)
      if (resp.status === 204) {
        this.firstNameModal = patient.firstName
        this.lastNameModal = patient.lastName
        this.openResendMailModal = true
      }
    },
    async savePatient() {
      if (isEmail(this.patientToEdit.email)) {
        await this.$store.dispatch(
          "admin/editAdminPatientApplication",
          this.patientToEdit
        )
        this.isShowEditModal = false
      } else {
        this.errorMsgEmail = true
      }
    },
    openCommentDetail(data) {
      this.patient = { ...data }
      this.openCommentDetailModal = true
    },
    patientAge(birthDateString) {
      const today = new Date()
      const birthDate = new Date(birthDateString)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    opnCreateModal() {
      this.isShowEditModal = true
    },
    editPatient(patient) {
      this.patientToEdit = { ...patient }
      this.isShowEditModal = true
    },
    openConfirmModal(patient) {
      this.patientAccountToDesable = patient
      this.isCloseAccountModal = true
    },
    sort(group, column) {
      if (column.sortable) {
        const { name } = column

        if (this.sortBy.key === name) {
          this.sortBy.order = -this.sortBy.order
        } else {
          this.sortBy = {
            group: group.name,
            key: name,
            order: -1
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      patientsList: "admin/patientApplications"
    }),
    isReadyToSave() {
      return (
        !this.patientToEdit.firstName ||
        !this.patientToEdit.lastName ||
        !this.patientToEdit.email
      )
    },
    displayColumns() {
      const cols = []
      Object.keys(this.columns).forEach((group) => {
        if (this.columns[group].display) {
          cols.push(this.columns[group])
        }
      })
      return cols
    },
    patients() {
      return this.patientsList.map((patient) => {
        return {
          main: {
            lastActivity: "",
            firstname: patient.firstName,
            lastname: patient.lastName,
            phone: "",
            commentary: ""
          },
          area: {
            country: "",
            agencies: [],
            department: "",
            establishmentName: ""
          },
          patientDetails: {
            zipcode: "",
            city: "",
            streetNumber: "",
            street: "",
            precision: "",
            email: patient.email,
            id: patient.patientId
          },
          state: {
            capacities: this.$t(`functionalCapacity.${patient.functionalCapacity}`)
          },
          usage: {
            usageDays:
              (patient.computedStats.usageDaysLast7Days
                ? patient.computedStats.usageDaysLast7Days
                : 0) + "j",
            usageTime:
              (patient.computedStats.usageLast7Days
                ? patient.computedStats.usageLast7Days
                : 0) + "h",
            performance:
              (patient.computedStats.performancePercentLast7Days
                ? patient.computedStats.performancePercentLast7Days
                : 0) + "%",
            selfWorkout:
              (patient.computedStats.selfWorkoutPercentLast7Days
                ? patient.computedStats.selfWorkoutPercentLast7Days
                : 0) + "%",
            online: 0,
            onlineTime: 0,
            lastAchievement: "",
            rank: "",
            activities: 0
          },
          lifebloomTeam: {
            expert: {
              firstname: "Firstname",
              lastname: "Lastname"
            },
            rehabilitator: {
              firstname: "Firstname",
              lastname: "Lastname"
            }
          },
          coaching: {
            coachingCount: 5,
            last: "",
            next: "",
            enableCoaching: false
          },
          equipment: {
            deviceId: "",
            wheelSize: "",
            deadlineMaintenance: "",
            changeDevice: ""
          },
          billing: {},
          billingDetails: {},
          closeAccount: {}
        }
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.buttons-container {
  margin-top: 24px;
  display: flex;

  a {
    margin: 0 6px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.monitoring-table {
  display: inline-block;
  overflow: scroll;
  width: 100%;
  padding-bottom: 20px;
  margin-top: 50px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .group {
      border-left: 2px solid $white-color;

      &:first-child {
        border-left: none;
      }
    }

    .group-head {
      font-size: 1.2rem;
      text-align: center;
      border-radius: 10px 10px 0px 0px;
    }

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
  }

  th {
    padding: 10px;
    user-select: none;
    vertical-align: bottom;
  }

  td {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;

    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  .separator {
    border-left: 2px solid $main-color;
  }
}
.svg-container {
  width: 28px;
  margin: 0px auto;
  margin-top: 5px;
  cursor: pointer;
}
.button-container {
  width: 248px;
  margin: 30px 0px !important;
}
.container-modal {
  text-align: center;
  padding: 0 50px;
}
.width-btn {
  width: 176px;
  margin: 0 auto;
  margin-top: 20px;
}
.font-title-modal {
  color: #0c2298;
  font-size: 18px;
}
.email-warning-txt {
  font-size: 15px;
  color: red;
  display: flex;
  margin-left: 10px;
}
</style>
