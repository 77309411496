<template>
  <Modal @close="close">
    <div class="margin-top">
      <div class="check-icon">
        <SVGCheck />
      </div>
      <div>
        Le lien est renvoyé à <span class="white">{{ space }}</span>
        <span class="bold-txt">{{ firstName }} {{ lastName }} </span>
        <span class="white">{{ space }} </span>
        avec succès
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/ConfirmationModal/Modal"
import SVGCheck from "@/assets/icons/check.svg"
export default {
  components: {
    Modal,
    SVGCheck
  },
  name: "ResendMailModal",
  props: {
    firstName: {
      type: String,
      default: ""
    },
    lastName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      space: "a"
    }
  },
  methods: {
    close() {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
.bold-txt {
  font-weight: bold;
}
.margin-top {
  margin-top: 150px;
  font-size: 18px;
  padding: 0px 56px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.white {
  color: white;
}
.check-icon {
  width: 40px;
  margin-right: 10px;
  color: $secondary-color;
}
</style>
